
























































@import '@design';

.footer-loc {
  .r-title {
    line-height: 1.4;
  }
  .location--phone-link {
    color: white;
    text-decoration: none;
  }
}
